<template>
	<div class="h-screen animate-ltr-linear-infinite bg-login bg-repeat">
		<swiper class="h-full w-screen" :slides-per-view="1" :space-between="50" :parallax="true" :pagination="{ clickable: true }" @swiper="onSwiper" @slideChange="onSlideChange" @reachEnd="onReachEnd">
			<swiper-slide class="flex w-full flex-col items-center justify-center" v-for="banner in banners" :key="banner">
				<p v-if="banner.title" class="mb-8 text-2xl">{{ banner.title }}</p>
				<img :src="banner.img" alt="" />
			</swiper-slide>
		</swiper>
	</div>
</template>

<script>
import SwiperCore, { Parallax, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
// import "swiper/swiper.scss";
// import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Parallax, Pagination])

export default {
	components: {
		Swiper,
		SwiperSlide,
	},

	data() {
		return {
			showPopup: null,
			popup: false,
			banners: [
				{
					title: this.$t('tutorial.tutorial1'),
					img: require('../assets/img/tutorial1.png'),
				},
				{
					title: this.$t('tutorial.tutorial2'),
					img: require('../assets/img/tutorial2.png'),
				},
				{
					title: this.$t('tutorial.tutorial3'),
					img: require('../assets/img/tutorial3.png'),
				},
				{
					title: '',
				},
			],
		}
	},
	methods: {
		async onReachEnd() {
			localStorage.setItem('tutorialSkipped', true)
			this.$router.push('/launch')
		},
	},
}
</script>
